import React from "react";
import { Button, Container, Nav, Navbar, Image } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { GrFacebookOption } from "react-icons/gr";
import { ImYoutube2 } from "react-icons/im";
import "./index.css";

export function Header() {
  const AboutUsMenu = () => {
    return (
      <div className="container-submenu">
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href = "https://sadeaf.org.sg/about-us/history/")
          }
        >
          <span className="link-submenu">History</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/about-us/about-sadeaf/")
          }
        >
          <span className="link-submenu">
            Vision, Mission, Core Values & Logo
          </span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/about-us/exco-subcommittees/")
          }
        >
          <span className="link-submenu">Executive Council & Committees</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/about-us/secretariat/")
          }
        >
          <span className="link-submenu">Secretariat</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/corporate-governance/")
          }
        >
          <span className="link-submenu">Corporate Governance</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/about-us/constitution/")
          }
        >
          <span className="link-submenu">Constitution of The Singapore Association for the Deaf</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          style={{ borderBottom: "none" }}
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/about-us/financial-information/")
          }
        >
          <span className="link-submenu">Financial Information</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          style={{ borderBottom: "none" }}
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/about-us/whistle-blowing-policy-procedures/")
          }
        >
          <span className="link-submenu">Whistle Blowing Policy & Procedures</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          style={{ borderBottom: "none" }}
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/about-us/data-protection-policy/")
          }
        >
          <span className="link-submenu">Data Protection Policy</span>
        </Nav.Link>
      </div>
    );
  };

  const ServicesSubMenu = () => {
    return (
      <div className="container-submenu-services">
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/service/interpreting/")
          }
        >
          <span className="link-submenu">Sign Language Interpretation</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href = "https://sadeaf.org.sg/service/notetaking/")
          }
        >
          <span className="link-submenu">Notetaking</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/the-little-hands-bilingual-bicultural-programme/")
          }
        >
          <span className="link-submenu">
            The Little Hands Bilingual-Bicultural Programme
          </span>
        </Nav.Link>
      </div>
    );
  };

  const ServicesSubMenu2 = () => {
    return (
      <div className="container-submenu-services2">
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/deaf-education/mayflower-primary-school/")
          }
        >
          <span className="link-submenu">Mayflower Primary School</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/deaf-education/beatty-secondary-school/")
          }
        >
          <span className="link-submenu">Beatty Secondary School</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/deaf-education-courses-sped/")
          }
        >
          <span className="link-submenu">Deaf Education Courses (SPED)</span>
        </Nav.Link>
      </div>
    );
  };

  const NewsMediaSubmenu = () => {
    return (
      <div className="container-submenu-news">
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href = "https://sadeaf.org.sg/sgsl-resources/")
          }
        >
          <span className="link-submenu">SgSL Resources</span>
        </Nav.Link>
      </div>
    );
  };

  const AboutDeafness = () => {
    return (
      <div className="container-submenu">
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/about-deafness/introduction/")
          }
        >
          <span className="link-submenu">Introduction</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/about-deafness/about-sign-language/")
          }
        >
          <span className="link-submenu">
            Sign Language & Sign Systems used
          </span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/deaf-communityculture/")
          }
        >
          <span className="link-submenu">Deaf Culture & Community</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/faq-on-sadeaf-and-about-the-deaf-and-hard-of-hearing/")
          }
        >
          <span className="link-submenu">FAQ</span>
        </Nav.Link>
      </div>
    );
  };

  const Services = () => {
    return (
      <div className="container-submenu">
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href = "https://sadeaf.org.sg/service/cs/")
          }
        >
          <span className="link-submenu">Community Services</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href = "https://sadeaf.org.sg/legal-clinic/")
          }
        >
          <span className="link-submenu">Legal Clinic</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/service/itinerant-support-service/")
          }
        >
          <span className="link-submenu">Itinerant Support Services</span>
        </Nav.Link>
        <Nav.Link className="container-link access-services">
          <span className="link-submenu">Deaf Access Services</span>
          <ServicesSubMenu />
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href = "https://sadeaf.org.sg/service/hcc/")
          }
        >
          <span className="link-submenu">Hearing Care Centre</span>
        </Nav.Link>
        <Nav.Link className="container-link deaf-education">
          <span className="link-submenu">Deaf Education</span>
          <ServicesSubMenu2 />
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href = "https://sadeaf.org.sg/service/schools/")
          }
        >
          <span className="link-submenu">Affiliated School</span>
        </Nav.Link>
      </div>
    );
  };

  const LanguageCourse = () => {
    return (
      <div className="container-submenu">
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/about-deafness/deaf-culture-and-sign-language-programme/")
          }
        >
          <span className="link-submenu">
            Deaf Culture and Sign Language Programme
          </span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href = "https://sadeaf.org.sg/sgsl-course/")
          }
        >
          <span className="link-submenu">Singapore Sign Language Course</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/deaf-awareness-programme/")
          }
        >
          <span className="link-submenu">Deaf Awareness Programme</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/micro-learning-course/")
          }
        >
          <span className="link-submenu">
            Introduction to the Singapore Deaf Community and Language
            (Micro-learning Course)
          </span>
        </Nav.Link>
      </div>
    );
  };

  const NewsMedia = () => {
    return (
      <div className="container-submenu">
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/news-media/news-media/")
          }
        >
          <span className="link-submenu">News & Media</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/news-media/annual-report/")
          }
        >
          <span className="link-submenu">Annual Reports</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/news-media/newsletter/")
          }
        >
          <span className="link-submenu">SIGNAL Newsletter</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/news-media/events_activities/")
          }
        >
          <span className="link-submenu">Upcoming Events & Activities</span>
        </Nav.Link>
        <Nav.Link className="container-link publications">
          <span className="link-submenu">Publications & Resources</span>
          <NewsMediaSubmenu />
        </Nav.Link>
      </div>
    );
  };
  const JoinUs = () => {
    return (
      <div className="container-submenu">
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/join-us/career-with-us/")
          }
        >
          <span className="link-submenu">Careers With Us</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/join-us/be-our-ambassador/")
          }
        >
          <span className="link-submenu">Be Our Ambassador</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/join-us/be-our-client/")
          }
        >
          <span className="link-submenu">Be Our Client</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://sadeaf.org.sg/join-us/be-our-member/")
          }
        >
          <span className="link-submenu">Be Our Member</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() =>
            (window.location.href =
              "https://volunteer.sadeaf.org.sg/")
          }
        >
          <span className="link-submenu">Be Our Volunteer</span>
        </Nav.Link>
      </div>
    );
  };

  return (
    <>
      <Container>
        <div className="nav-container">
          <LazyLoadImage
            src={
              "https://sadeaf.org.sg/wp-content/uploads/2015/12/sadeaflogo.gif"
            }
            style={{
              height: "90px",
              width: "120px",
              boxSizing: "border-box",
            }}
            className="logo-sadeaf-header"
          />
          <Navbar expand="md">
            <Container
              style={{ display: "flex", justifyContent: "center"}}
              className="container-header"
            >
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav style={{margin: "0px auto"}} className="me-auto nav-list">
                  <Nav.Link
                    className="link about"
                    style={{
                      fontFamily: "Open Sans",
                      color: "#1b1b1b",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    <span>About Us</span>
                    <AboutUsMenu />
                  </Nav.Link>
                  <Nav.Link
                    className="link"
                    style={{
                      fontFamily: "Open Sans",
                      color: "#1b1b1b",
                      fontWeight: "600",
                      fontSize: "14px",
                      marginLeft: "10px",
                    }}
                  >
                    <span>About Deafness</span>
                    <AboutDeafness />
                  </Nav.Link>

                  <Nav.Link
                    className="link"
                    style={{
                      fontFamily: "Open Sans",
                      color: "#1b1b1b",
                      fontWeight: "600",
                      fontSize: "14px",
                      marginLeft: "10px",
                    }}
                  >
                    <span>Services</span>
                    <Services />
                  </Nav.Link>

                  <Nav.Link
                    className="link"
                    style={{
                      fontFamily: "Open Sans",
                      color: "#1b1b1b",
                      fontWeight: "600",
                      fontSize: "14px",
                      marginLeft: "10px",
                    }}
                  >
                    <span> Sign Language Courses</span>
                    <LanguageCourse />
                  </Nav.Link>
                  <Nav.Link
                    className="link"
                    style={{
                      fontFamily: "Open Sans",
                      color: "#1b1b1b",
                      fontWeight: "600",
                      fontSize: "14px",
                      marginLeft: "10px",
                    }}
                  >
                    <span>News & Media</span>
                    <NewsMedia />
                  </Nav.Link>
                  <Nav.Link
                    className="link"
                    style={{
                      fontFamily: "Open Sans",
                      color: "#1b1b1b",
                      fontWeight: "600",
                      fontSize: "14px",
                      marginLeft: "10px",
                    }}
                  >
                    <span> Join Us</span>
                    <JoinUs />
                  </Nav.Link>
                  <Nav.Link
                    className="link"
                    style={{
                      fontFamily: "Open Sans",
                      color: "#1b1b1b",
                      fontWeight: "600",
                      fontSize: "14px",
                      marginLeft: "10px",
                    }}
                    onClick={() =>
                      (window.location.href = "https://donate.sadeaf.org.sg/")
                    }
                  >
                    <span>Donate</span>
                  </Nav.Link>
                  <Nav.Link
                    className="link"
                    style={{
                      fontFamily: "Open Sans",
                      color: "#1b1b1b",
                      fontWeight: "600",
                      fontSize: "14px",
                      marginLeft: "10px",
                    }}
                    onClick={() =>
                      (window.location.href =
                        "https://sadeaf.org.sg/donate/")
                    }
                  >
                    <span>Donation Methods</span>
                  </Nav.Link>
                  <Nav.Link
                    className="link"
                    style={{
                      fontFamily: "Open Sans",
                      color: "#1b1b1b",
                      fontWeight: "600",
                      fontSize: "14px",
                      marginLeft: "10px",
                    }}
                    onClick={() =>
                      (window.location.href = "https://sadeaf.org.sg/contact-us/")
                    }
                  >
                    <span>Contact Us</span>
                  </Nav.Link>
                  <Nav.Link
                    className="link"
                    style={{
                      fontFamily: "Open Sans",
                      color: "#1b1b1b",
                      fontWeight: "600",
                      fontSize: "14px",
                      marginLeft: "10px",
                    }}
                    onClick={() =>
                      (window.location.href =
                        "https://www.carousell.sg/sadeafshop/")
                    }
                  >
                    <span>Shop@SADeaf</span>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <div className="container-icon">
            <div className="icon facebook">
              <GrFacebookOption
                className="icon-img"
                onClick={() =>
                  (window.location.href = "https://www.facebook.com/SADeafSG/")
                }
              />
            </div>
            <div className="icon youtube">
              <ImYoutube2
                className="icon-img"
                onClick={() =>
                  (window.location.href =
                    "https://www.youtube.com/user/TheSADeaf")
                }
              />
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
