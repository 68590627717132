import React, {Suspense, lazy} from 'react';
import {Header} from '../../Components/Navbar/index';
import {TitleImage} from '../../Components/TitleImage';
import {Spinner} from 'react-bootstrap';
import {Helmet} from 'react-helmet';

const FormIntake = lazy(() => import('../../Components/FormIntake/index.js'));

const Intake = () => {
  return (
    <>
      <div>
        <Helmet>
          <title>SADeaf Intake</title>
          <meta name="title" content="SADeaf Intake" />
          <meta name="description" content="SADeaf Intake" />
        </Helmet>
        <Header />
        <TitleImage title={'Intake'} />
        <Suspense
          fallback={
            <Spinner
              animation="border"
              variant="primary"
              className="m-auto d-flex justify-content-center align-items-center"
            />
          }>
          <FormIntake />
        </Suspense>
      </div>
    </>
  );
};

export default Intake;
